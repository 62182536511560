.payment-screen {
  padding: 2rem;
  background-color: #f9fafb; /* light gray */
}

.order-summary,
.payment-options {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.order-summary h2,
.payment-options h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937; /* gray-800 */
  margin-bottom: 1rem;
}

.cart-item {
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb; /* gray-200 */
  padding-bottom: 1rem;
}

.product-name {
  font-weight: bold;
  color: #34D399; /* green-500 */
}

.total-amount {
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937; /* gray-800 */
}

.payment-options h5 {
  font-weight: bold;
  color: #4b5563; /* gray-600 */
  margin-bottom: 0.5rem;
}

.payment-button,
.offline-payment-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #fbbf24; /* yellow-600 */
  color: white;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.payment-button:hover,
.offline-payment-button:hover {
  background-color: #f59e0b; /* yellow-700 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.offline-payment p {
  font-size: 0.875rem; /* 14px */
  color: #6b7280; /* gray-500 */
}

.error-message {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f87171; /* red-400 */
  color: white;
  border-radius: 8px;
  text-align: center;
}

.cart-item .options {
  margin-top: 0.5rem;
}

.cart-item .option-item {
  font-size: 0.875rem; /* 14px */
  color: #4b5563; /* gray-600 */
}

.cart-item .options-label {
  font-weight: bold;
  color: #1f2937; /* gray-800 */
}

.cart-item .options p {
  padding-left: 1.25rem;
}

@media (max-width: 768px) {
  .payment-screen {
    padding: 1rem;
  }

  .order-summary,
  .payment-options {
    padding: 1rem;
  }

  .payment-button,
  .offline-payment-button {
    padding: 0.5rem;
  }
}
