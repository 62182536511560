/* Overall layout */
.cart-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .header {
    background-color: #fbbf24;
    padding: 1rem;
  }
  
  .title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .summary {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  }
  
  .total-label {
    color: #4b5563;
  }
  
  .total-amount {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .checkout-btn {
    background-color: #fbbf24;
    padding: 1rem 2rem;
    border-radius: 0.375rem;
    color: black;
    font-weight: bold;
  }
  
  .checkout-btn:disabled {
    background-color: #d1d5db;
    cursor: not-allowed;
  }
  
  .cart-items {
    flex-grow: 1;
    overflow: auto;
    padding: 1rem;
  }
  
  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .empty-cart-icon {
    width: 3rem;
    height: 3rem;
    color: #d1d5db;
  }
  
  .empty-cart-text {
    font-size: 1.25rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  
  .cart-item {
    background-color: white;
    border-radius: 0.375rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
  
  .product-name {
    font-size: 1.125rem;
    font-weight: bold;
  }
  
  .options {
    margin-top: 0.5rem;
  }
  
  .options-label {
    font-weight: 500;
  }
  
  .option-item {
    color: #4b5563;
    font-size: 0.875rem;
  }
  
  .item-footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    color: #16a34a;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .quantity-btn {
    background: none;
    border: none;
    color: #4b5563;
    cursor: pointer;
  }
  
  .quantity-btn:hover {
    color: #374151;
  }
  
  .quantity {
    font-size: 1.125rem;
  }
  
  .remove-btn {
    background: none;
    border: none;
    color: #dc2626;
    cursor: pointer;
  }
  
  .remove-btn:hover {
    color: #991b1b;
  }
  
  .remove-icon, .quantity-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  