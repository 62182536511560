/* BrandAdBanner.css */
.carousel-container-1{
  left: 76px;
  position: relative;
  width: 90%;
  height: 650px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 30px;
  margin-bottom: 80px;
}

.carousel-track {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
 top:80%;
   left: 5%;
  right: 0; 
  padding: 2px;

   background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent); 
  color: white;
}

.text-overlay h3 {
  margin: 0;
  font-size: 45px;
  font-weight: bold;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  z-index: 2;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.95);
}

.prev {
  left: 16px;
}

.next {
  right: 16px;
}

.dots-container {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 2;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: white;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #fee2e2;
  color: #dc2626;
}

.loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #f3f4f6;
  color: #6b7280;
}

/* Added animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel-slide {
  animation: fadeIn 0.5s ease-in-out;
}

/* Media Query for Mobile Devices */
@media (max-width: 900px) {
  .carousel-container-1 {
    left: 0;
    width: 100%;
    height: 400px; /* Reduce the height for mobile screens */
    margin-top: 20px;
    overflow: hidden;
    margin-bottom: 40px;
  }

  .text-overlay {
    top: 70%;
    left: 5%;
    padding: 4px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
    font-size: 14px; /* Reduce font size for readability */
  }

  .text-overlay h3 {
    font-size: 28px;
    font-weight: bold;
  }

  .nav-button {
    width: 32px;
    height: 32px;
    font-size: 18px;
  }

  .prev {
    left: 8px;
  }

  .next {
    right: 8px;
  }

  .dots-container {
    bottom: 8px;
    gap: 4px;
  }

  .dot {
    width: 6px;
    height: 6px;
  }
}

/* Media Query for Extra Small Devices */
@media (max-width: 480px) {
  .carousel-container-1 {
    height: 300px; /* Further reduce height for very small screens */
  }

  .text-overlay h3 {
    font-size: 22px;
  }

  .nav-button {
    width: 28px;
    height: 28px;
    font-size: 16px;
  }

  .dots-container {
    gap: 2px;
  }

  .dot {
    width: 5px;
    height: 5px;
  }
}
