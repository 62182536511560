/* Subcategory Grid */
.subcategory-grid {
  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 40px;
    padding: 20px;
    margin: 50px auto;
    max-width: 1200px;
    justify-content: center;
}

/* Subcategory Card */
.subcategory-card {
  background: white;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

/* Hover effect */
.subcategory-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Subcategory Image */
.subcategory-image {
  width: 100%;
  max-height: 200px; /* Prevents images from stretching too much */
  aspect-ratio: 4 / 3; /* Keeps images proportionate */
  object-fit: contain; /* Ensures the entire image is visible without cropping */
  border-radius: 6px;
  display: block;
  margin: 0 auto;
}

/* Title Styling */
.subcategory-card h2 {
  font-size: 18px;
  margin: 10px 0;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .subcategory-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .subcategory-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .subcategory-card {
    padding: 10px;
  }

  .subcategory-card h2 {
    font-size: 16px;
  }

  .subcategory-image {
    max-height: 180px; /* Adjusted for smaller screens */
  }
}
