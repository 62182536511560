/* Grid container for layout */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three items per row */
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto; /* Centering the grid */
}

/* Category card */
.category-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

/* Category title */
.category-title {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: center;
}

/* Card styling */
.card {
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  position: relative;
  width: 100%; /* Make it flexible */
  max-width: 400px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* Hover effect */
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Image styling */
.image {
  width: 100%;
  height: 250px; /* Adjusted height for better aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Title styling */
.title {
  margin: 10px 0 0;
  font-size: 18px;
  color: #333;
  text-align: center;
}

/* Responsive Layout */

/* Tablets */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Two items per row */
  }
}

/* Mobile */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* One item per row */
    gap: 15px;
  }

  .card {
    max-width: 100%; /* Full width */
  }
}
