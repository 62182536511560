.product-container {
  max-width:auto;
  margin: 0 auto;
  padding: 20px;
}

.carousel-container {
  width: 100%;
  margin: 20px auto;
}

/* Main product image swiper */
.product-swiper {
  width: 90%;
  margin-bottom: 20px;
}

.product-swiper .swiper-slide {
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  height: 500px; /* Increased height */
}


.carousel-image-zoom {
  width: 100%;
  height: 100%;
  object-fit: contain; /* This maintains aspect ratio */
  max-height: 500px; /* Matches slide height */
}

/* Thumbnail swiper */
.product-thumbnails {
  width: 40%;
  height: auto; /* Increased height for thumbnails */
  box-sizing: border-box;
  padding: 10px 0;
}

.product-thumbnails .swiper-slide {
  width: 120px; /* Increased width */
  height: auto; /* Increased height */
  /* opacity: 0.4; */
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.product-thumbnails .swiper-slide-thumb-active {
  opacity: 1;
  border-color: #007bff;
}

.carousel-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #333;
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
}


/* Loading Spinner Styling */
.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ffc107;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error Message Styling */
.error-message {
  padding: 15px;
  margin: 20px 0;
  background-color: #ffebee;
  border: 1px solid #ef5350;
  border-radius: 4px;
  color: #c62828;
}

/* Product Info Section Styling */
.product-info {
  margin-bottom: 30px;
}

.product-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

/* Dropdowns Container Styling */
.dropdowns-container {
  margin-bottom: 30px;
}

.dropdown-group {
  margin-bottom: 20px;
}

.dropdown-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.dropdown-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.dropdown-select:focus {
  outline: none;
  border-color: #ffc107;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
}

/* Quantity Selector Styling */
.quantity-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 15px;
}

.quantity-label {
  font-weight: 600;
  color: #333;
}

.quantity-button {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background-color: #ffc107;
  color: black;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.quantity-button:hover {
  background-color: #ffb300;
}

.quantity-value {
  font-weight: 600;
  font-size: 18px;
  min-width: 30px;
  text-align: center;
}

/* Price Display Section */
.price-display {
  margin-bottom: 30px;
}

.price-label {
  font-size: 16px;
  color: #666;
}

.price-value {
  font-size: 24px;
  font-weight: bold;
  color: #2e7d32;
}

/* Action Buttons Styling */
.action-buttons {
  position: sticky;
  z-index: 50;
  bottom: 0;
  background-color: white;
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 15px;
}

.action-button {
  flex: 1;
  padding: 15px;
  border: 2px solid black;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #ffc107;
  color: black;
}

.action-button:hover {
  background-color: #ffb300;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .carousel-image-zoom {
    height: 300px;
  }

  .carousel-thumbnail {
    height: 80px;
  }

  .action-buttons {
    flex-direction: column;
  }

  .product-title {
    font-size: 20px;
  }

  .price-value {
    font-size: 20px;
  }
}
