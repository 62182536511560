.Titel {
  display: flex;
  flex-direction: column;
  font-size: 40px;
  margin: 0;
  padding: 0;
  align-items: center;
}


.heading-smail-1{
  font-size: 30px;
  margin: 0; /* Remove any space around heading-1 */
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 20px;

}
.heading-1 {
  font-size: 55px;
  margin: 0; /* Remove any space around heading-1 */
  font-family: "Times New Roman", Times, serif;
}

.heading-2 {
  font-size: 60px;
  margin: 0;
  margin-bottom: 50px;
  font-family: "Times New Roman", Times, serif;
   /* Remove any space around heading-2 */
}
