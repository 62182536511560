/* Contact Widget */
.contact-widget {
  background-color: #F9C600;
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row; 
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.contact-widget:hover {
  transform: scale(1.05);
}

.contact-widget-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: center;
  color: black;
}

/* Animated Text */


@keyframes fadeInFromTop {
  0% { opacity: 1; transform: translateY(-20px); }
  50% { opacity: 1; transform: translateY(0); }
  100% { opacity: 1; transform: translateY(-20px); }
}

/* Contact Form Styles */
.contact-form {
  width: 100%;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid black;
  font-size: 16px;
  background: none;
  outline: none;
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #F9C600;
}

.contact-form button {
  background-color: #25D366;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.contact-form button:hover {
  background-color: #1EBE55;
}

/* Typography Fixes */
.contact-heading {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

.contact-subheading {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 30px;
}

/* Responsive Fixes */
@media (max-width: 1024px) {
  .contact-heading { font-size: 2.5rem; }
  .contact-subheading { font-size: 1.3rem; }
}

@media (max-width: 768px) {
  .contact-widget {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .contact-heading { font-size: 2rem; }
  .contact-subheading { font-size: 1.2rem; }
}

@media (max-width: 480px) {
  .contact-widget { flex-direction: column; gap: 5px; }
  .contact-heading { font-size: 1.8rem; }
  .contact-subheading { font-size: 1rem; }
}
