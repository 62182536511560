.modal-main {
    background-color: #ffffff; /* White background */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin: auto;
    text-align: center;
  }
  
  .modal-title {
    font-size: 20px;
    font-weight: bold;
    color: #d97706; /* Yellow-700 color */
    margin-bottom: 16px;
  }
  
  .modal-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #d1d5db; /* Light gray */
    border-radius: 4px;
    font-size: 16px;
  }
  
  .modal-error {
    color: #ef4444; /* Red for error messages */
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .modal-success {
    color: #10b981; /* Green for success messages */
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .modal-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
  }
  
  .modal-cancel {
    background-color: #e5e7eb; /* Light gray */
    color: #374151; /* Dark gray */
  }
  
  .modal-cancel:hover {
    background-color: #d1d5db; /* Hover light gray */
  }
  
  .modal-submit {
    background-color: #d97706; /* Yellow-700 */
    color: #ffffff; /* White text */
  }
  
  .modal-submit:hover {
    background-color: #b45309; /* Darker yellow */
  }
  
  .modal-submit:disabled {
    background-color: #fcd34d; /* Lighter yellow for disabled state */
    cursor: not-allowed;
  }
  