.dialog {
    width: 400px;
    margin: 100px auto;
    padding: 20px;
    background-color: #ffffff; /* White background */
    border: 1px solid #fdd835; /* Yellow 700 as border */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .dialog h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fbc02d; /* Yellow 700 */
  }
  
  .dialog p {
    font-size: 16px;
    color: #616161; /* Neutral dark gray for readability */
    margin-bottom: 20px;
  }
  
  .dialog input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #fdd835; /* Yellow 700 */
    border-radius: 4px;
    font-size: 14px;
  }
  
  .dialog button {
    padding: 10px 20px;
    margin: 5px;
    font-size: 14px;
    color: #ffffff; /* White text for buttons */
    background-color: #fbc02d; /* Yellow 700 */
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .dialog button:hover {
    background-color: #fdd835; /* Slightly lighter yellow on hover */
  }
  
  .dialog button:disabled {
    background-color: #e0e0e0; /* Gray for disabled buttons */
    cursor: not-allowed;
  }
  