/* Modal overlay to cover the screen */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content box */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  /* Styling for buttons */
  .modal-actions button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  /* Button hover effect */
  .modal-actions button:hover {
    background-color: #0056b3;
  }
  