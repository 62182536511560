/* Full-page overlay */
.signup-form-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

/* Centered Form Container */
.signup-form-container {
  background: white;
  border-radius: 8px;
  padding: 25px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 90vh; /* Ensures it never overflows */
  overflow-y: auto; /* Scroll inside container if needed */
}

/* Title */
.signup-form-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  text-align: center;
  color: #007bff;
}

/* Form Styles */
.signup-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Form Group */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

/* Labels */
.form-group label {
  font-weight: 600;
  font-size: 0.95rem;
  color: #333;
}

/* Input Fields */
.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Button Container */
.form-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Primary Button */
.btn-primary {
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Secondary Button */
.btn-secondary {
  background-color: #727a81;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-secondary:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .signup-form-container {
      padding: 20px;
      width: 95%;
  }

  .btn-primary, .btn-secondary {
      font-size: 0.9rem;
      padding: 10px;
  }
}
