/* General styles for the container */
.usermangment-container {
  margin-top: 40px;
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  width:100%;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Style for each item */
.usermangment-item {
  font-size: 18px;
  font-weight: 600;
  color: #f59e0b;
  cursor: pointer;
  margin-bottom: 16px;
  padding: 12px 20px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border: 1px solid #f59e0b;
}

/* Hover effect for items */
.usermangment-item:hover {
  background-color: #f59e0b;
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Specific styling for Address Management */
.address-management {
  color: #f59e0b;
  border: 1px solid #f59e0b;
}

.address-management:hover {
  background-color: #f59e0b;
  color: #ffffff;
}

/* Specific styling for Logout */
.logout {
  color: #ef4444;
  border: 1px solid #ef4444;
}

.logout:hover {
  background-color: #ef4444;
  color: #ffffff;
}

/* Add focus styles for accessibility */
.usermangment-item:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 159, 11, 0.4);
}
