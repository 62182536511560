

/* footer.css */

.footer {
    width: auto;
    background-color: black;
    color: #ecf0f1;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    text-align: left;
  }
  
  .footer .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer .footer-section {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .footer .footer-section h4 {
    font-size: 28px;
    margin-bottom: 10px;
    color: ffff;
  }
  
  .footer .footer-section p,
  .footer .footer-section ul {
    font-size: 14px;
  }
  
  .footer .footer-section a {
    color:white;
    text-decoration: none;
  }
  
  .footer .footer-section a:hover {
    text-decoration: underline;
  }
  
  .footer .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #bdc3c7;
  }
  