.address-list {
  padding: 2rem;
  background-color: #f9fafb; /* light gray */
}

.address-card {
  padding: 1.25rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.address-card.selected {
  border: 2px solid #34D399; /* green-500 */
  background-color: #d1fae5; /* green-50 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.address-card:hover {
  transform: scale(1.02);
  border: 2px solid #fbbf24; /* yellow-500 */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

.address-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.address-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.address-info p {
  font-weight: bold;
  color: #1f2937; /* gray-800 */
  font-size: 1.125rem; /* 18px */
}

.address-detail {
  display: flex;
  align-items: center;
  color: #4b5563; /* gray-600 */
  font-size: 0.875rem; /* 14px */
}

.address-detail svg {
  margin-right: 0.5rem;
  color: #9ca3af; /* gray-400 */
}

.address-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.add-address-button {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #fbbf24; /* yellow-600 */
  color: white;
  border-radius: 0.5rem; /* rounded */
  text-align: center;
  font-weight: bold;
  font-size: 1rem; /* 16px */
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-address-button:hover {
  background-color: #f59e0b; /* yellow-700 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.text-center p {
  font-size: 0.9rem;
  color: #6b7280; /* gray-500 */
}

.text-center h3 {
  color: #fbbf24; /* yellow-600 */
}

.btn-proceed{
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #fbbf24; /* yellow-600 */
  color: white;
  border-radius: 0.5rem; /* rounded */
  font-weight: bold;
  font-size: 1rem; /* 16px */
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-proceed:hover {
  background-color: #f59e0b; /* yellow-700 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.btn-proceed:disabled {
  background-color: #d1d5db; /* gray-300 */
  color: #6b7280; /* gray-500 */
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .address-list {
    padding: 1rem;
  }

  .address-card {
    padding: 1rem;
  }

  .add-address-button,
  .proceed-button {
    padding: 0.5rem;
  }
}
