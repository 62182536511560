.reachout-widget {
  background-color: #FFD700; /* Yellow */
  padding: 20px;
  display: flex;
  flex-direction: column; /* Stack text and button */
  align-items: center;
  justify-content: center;
  gap: 15px; /* Space between text and button */
  margin-top: 100px;
  text-align: center;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
}

.reachout-heading {
 font-size: 18px;
 color: black;
 margin: 0;
}

.reachout-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
}

.reachout-button:hover {
  background-color: #f9a825;
  color: black;
  transform: scale(1.1);
}

.reachout-button:focus {
  outline: none;
}

/* Responsive Design */
@media (max-width: 900px) {
 .reachout-widget {
   height: auto;
   padding: 30px;
 }

 .reachout-heading {
   font-size: 16px;
 }

 .reachout-button {
   font-size: 14px;
   padding: 8px 16px;
 }
}
