/* AddressForm.css */
.address-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .input-field {
    padding: 0.75rem;
    border: 1px solid #d1d5db; /* Light gray border */
    border-radius: 8px;
    font-size: 1rem;
    width: 100%;
  }
  
  .input-field:focus {
    border-color: #fbbf24; /* Yellow border on focus */
    outline: none;
  }
  
  .submit-button {
    padding: 0.75rem 1.25rem;
    background-color: #fbbf24; /* Yellow background */
    color: white;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #f59e0b; /* Darker yellow on hover */
  }
  
  .submit-button:focus {
    outline: none;
  }
  
  .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  