/* Overlay styles */
.login-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    padding: 20px;
}

/* Container styles */
.login-container {
    max-width: 500px;
    width: 90%;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.login-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 3rem;
    text-align: center;
    color: #FFC107;
}

/* Form styles */
.login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    position: relative;
}

.form-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-input:focus {
    border-color: #FFC107;
    outline: none;
    box-shadow: 0 0 5px rgba(255, 193, 7, 0.5);
}

.password-field .form-input {
    padding-right: 40px;
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #FFC107;
    font-size: 1rem;
    cursor: pointer;
}

/* Error message */
.error-message {
    color: #e63946;
    font-size: 0.85rem;
    text-align: center;
}

/* Button styles */
.form-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.btn-primary,
.btn-secondary {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-primary {
    background-color: #FFC107;
    color: white;
}

.btn-primary:hover {
    background-color: #e0a800;
    transform: translateY(-2px);
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
}

.btn-secondary:hover {
    background-color: #FFC107;
    transform: translateY(-2px);
}

.btn-primary:disabled {
    background-color: #FFC107;
    opacity: 0.6;
    cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 480px) {
    .login-container {
        padding: 15px;
        width: 95%;
    }

    .login-title {
        font-size: 1.3rem;
    }

    .form-input {
        font-size: 0.9rem;
        padding: 10px;
    }

    .btn-primary,
    .btn-secondary {
        font-size: 0.9rem;
        padding: 10px;
    }
}
