/* Modal Container (Full Screen Centered) */
body{
  width: 600px;
  margin: 70px auto;
  display: initial;
  margin-top: 200px;
}
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height */
  width: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay */
  position: fixed;
  top: 0;
  left: 0;
  
}

/* Modal Box */
.modal {
  background-color: black;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;

}

/* Title */
.modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #d97706; /* Yellow-700 */
  margin-bottom: 10px;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
}

/* Input Fields */
.modal-input {
  width: 60%;
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 16px;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  margin: 20px auto;
  display: block;
}

.modal-input:focus {
  border-color: #d97706;
  outline: none;
  box-shadow: 0 0 6px rgba(217, 119, 6, 0.5);
}

/* Error & Success Messages */
.modal-error {
  color: #ef4444;
  font-size: 14px;
  margin-bottom: 8px;
}

.modal-success {
  color: #10b981;
  font-size: 14px;
  margin-bottom: 8px;
}

/* Button Wrapper */
.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Buttons */
.modal-button {
  padding: 12px 18px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease, transform 0.2s ease;
}

/* Submit Button */
.modal-submit {
  background-color: #d97706;
  color: #ffffff;
}

.modal-submit:hover {
  background-color: #b45309;
  transform: translateY(-2px);
}

.modal-submit:disabled {
  background-color: #fcd34d;
  cursor: not-allowed;
}

/* RESPONSIVE DESIGN */
@media (max-width: 480px) {
  .modal {
      padding: 20px;
  }

  .modal-title {
      font-size: 18px;
  }

  .modal-input {
      font-size: 14px;
      padding: 10px;
  }

  .modal-buttons {
      flex-direction: column;
      gap: 8px;
  }

  .modal-button {
      font-size: 14px;
      padding: 10px;
  }
}
