/* ChatWidget.css */

/* Floating container on the left side */
.chat-widget-container {
    position: fixed;
    bottom: 20px; /* Adjust position from the bottom */
    right: 20px;   /* Adjust position from the left */
    z-index: 9999;
    cursor: pointer;
    animation: slideInOut 4s ease-in-out infinite; /* Infinite loop for animation */
  }
  
  /* Chat bubble style */
  .chat-widget {
    background-color: #25D366; /* WhatsApp green */
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  /* On hover, scale the chat bubble */
  .chat-widget:hover {
    transform: scale(1.1);
  }
  
