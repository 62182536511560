/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff; /* White background */
  padding: 10px 20px;
  height: 60px;
  position: relative;
}

/* Logo Section */
.navbar .logo img {
  height: 75px;
  margin-left: 30px;
}

/* Navbar Links */
.navbar .nav-links {
  display: flex;
  list-style-type: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

/* Individual Navbar Links */
.navbar .nav-links li a {
  color: #333;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  font-weight: 500;
}

/* Hover Effect for Links */
.navbar .nav-links li a:hover {
  background-color: #ffcc00;
}

/* Cart Badge */
.cart-space {
  display: flex;
  align-items: center;
  top: -15px;
  position: relative;
  margin-right: 30px;
  right: 13px;
  justify-content: center;

}

.cart-link {
  margin: 0;
}

/* Mobile Navbar (Hidden by Default) */
.mobile-nav {
  display: none;
}

.nav-links.open {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #ffff;
  padding: 10px 0;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-links li {
  text-align: center;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  /* .navbar{
    display:none;
  } */
  .navbar .nav-links {
    display: flex;
    display: none;
    /* list-style-type: none;
    gap: 20px;
    margin: 0;
    padding: 0; */
  }
  .mobile-nav {
    display: block;
    color: #333;
    font-size: 24px;
    cursor: pointer;
  }
}

.logo{
  top: -15px;
  position: relative;
  margin-left: 30px;
}